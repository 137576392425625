var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"mt-4"},[_c('b-card',{attrs:{"header-class":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('label',{staticClass:"mr-2 text-white",domProps:{"textContent":_vm._s('Grup Bilgileri')}}),_c('b-button',{staticClass:"ml-4",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Geri ")])]},proxy:true}])},[_c('b-container',{staticClass:"mt-4"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v(" Tarih Saat : ")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.form.createdAt ? _vm.form.createdAt .split("T")[0] .split("-") .reverse() .join(".") + " " + _vm.form.createdAt .split("T")[1] .split(".")[0] .replace(":", ".") : "")+" ")])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v(" Yarışmanın Adı : ")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.competition.name))])],1),_vm._l((_vm.form.personels),function(user){return _c('b-row',{key:user.id},[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Katılımcının Adı Soyadı :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{ name: 'PersonelDetail', params: { id: user.id } }},domProps:{"textContent":_vm._s(user.name + ' ' + user.surname)}})],1)],1)}),(_vm.form.personelBackup)?_c('b-row',{key:_vm.form.personelBackup.id},[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v(" Yedek Katılımcının Adı Soyadı : ")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'PersonelDetail',
                  params: { id: _vm.form.personelBackup.id },
                }},domProps:{"textContent":_vm._s(
                  _vm.form.personelBackup.name + ' ' + _vm.form.personelBackup.surname
                )}})],1)],1):_vm._e(),(_vm.form.personelBackup2)?_c('b-row',{key:_vm.form.personelBackup2.id},[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v(" Yedek Katılımcının Adı Soyadı : ")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_c('router-link',{attrs:{"to":{
                  name: 'PersonelDetail',
                  params: { id: _vm.form.personelBackup2.id },
                }},domProps:{"textContent":_vm._s(
                  _vm.form.personelBackup2.name + ' ' + _vm.form.personelBackup2.surname
                )}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Katılım Şekli :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.roleOptions.find(function (e) { return _vm.form.formOfParticipation == e.value; }) .text))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v(" Kategori : ")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.getCategory(_vm.form.category)))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Kulüp Adı :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.form.clubRel ? _vm.form.clubRel.name : ""))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Tekne Sınıfı:")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.getCategory(_vm.form.boatType)))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Mesafe :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.getCategory(_vm.form.distance) + (_vm.form.distance ? " metre" : "")))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Derece :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.form.teamDegree))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Ödül :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.form.prize ? "Evet" : "Hayır"))])],1),_c('b-row',[_c('b-col',{staticClass:"mt-4 text-danger font-weight-bold"},[_vm._v("Açıklama :")]),_c('b-col',{staticClass:"mt-4 font-weight-bold"},[_vm._v(_vm._s(_vm.form.description))])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-container class="mt-4">
      <b-card header-class="header">
        <template #header>
          <label v-text="'Grup Bilgileri'" class="mr-2 text-white"></label>
          <b-button variant="primary" @click="$router.back()" class="ml-4">
            Geri
          </b-button>
        </template>

        <b-container class="mt-4">
          <b-form>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">
                Tarih Saat :
              </b-col>
              <b-col class="mt-4 font-weight-bold">
                {{
                  form.createdAt
                    ? form.createdAt
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join(".") +
                      " " +
                      form.createdAt
                        .split("T")[1]
                        .split(".")[0]
                        .replace(":", ".")
                    : ""
                }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">
                Yarışmanın Adı :
              </b-col>
              <b-col class="mt-4 font-weight-bold">{{
                competition.name
              }}</b-col>
            </b-row>
            <b-row v-for="user in form.personels" :key="user.id">
              <b-col class="mt-4 text-danger font-weight-bold"
                >Katılımcının Adı Soyadı :</b-col
              >
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="user.name + ' ' + user.surname"
                  :to="{ name: 'PersonelDetail', params: { id: user.id } }"
                ></router-link>
              </b-col>
            </b-row>
            <b-row v-if="form.personelBackup" :key="form.personelBackup.id">
              <b-col class="mt-4 text-danger font-weight-bold">
                Yedek Katılımcının Adı Soyadı :
              </b-col>
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="
                    form.personelBackup.name + ' ' + form.personelBackup.surname
                  "
                  :to="{
                    name: 'PersonelDetail',
                    params: { id: form.personelBackup.id },
                  }"
                ></router-link>
              </b-col>
            </b-row>
            <b-row v-if="form.personelBackup2" :key="form.personelBackup2.id">
              <b-col class="mt-4 text-danger font-weight-bold">
                Yedek Katılımcının Adı Soyadı :
              </b-col>
              <b-col class="mt-4 font-weight-bold">
                <router-link
                  v-text="
                    form.personelBackup2.name + ' ' + form.personelBackup2.surname
                  "
                  :to="{
                    name: 'PersonelDetail',
                    params: { id: form.personelBackup2.id },
                  }"
                ></router-link>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold"
                >Katılım Şekli :</b-col
              >
              <b-col class="mt-4 font-weight-bold">{{
                roleOptions.find((e) => form.formOfParticipation == e.value)
                  .text
              }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">
                Kategori :
              </b-col>
              <b-col class="mt-4 font-weight-bold">{{
                getCategory(form.category)
              }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold"
                >Kulüp Adı :</b-col
              >
              <b-col class="mt-4 font-weight-bold">{{
                form.clubRel ? form.clubRel.name : ""
              }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold"
                >Tekne Sınıfı:</b-col
              >
              <b-col class="mt-4 font-weight-bold">{{
                getCategory(form.boatType)
              }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">Mesafe :</b-col>
              <b-col class="mt-4 font-weight-bold">{{
                getCategory(form.distance) + (form.distance ? " metre" : "")
              }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">Derece :</b-col>
              <b-col class="mt-4 font-weight-bold">{{ form.teamDegree }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold">Ödül :</b-col>
              <b-col class="mt-4 font-weight-bold">{{
                form.prize ? "Evet" : "Hayır"
              }}</b-col>
            </b-row>
            <b-row>
              <b-col class="mt-4 text-danger font-weight-bold"
                >Açıklama :</b-col
              >
              <b-col class="mt-4 font-weight-bold">{{
                form.description
              }}</b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-card>
    </b-container>
  </div>
</template>


<script>
import locationOptions from "@/store/constants/locationOptions";
import roleOptions from "@/store/constants/roleOptions";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      locationOptions,
      roleOptions,
    };
  },
  computed: {
    ...mapGetters([
      "getSingleCompetitionGroup",
      "getSingleCompetition",
      "getManagementItems",
    ]),
    form: {
      get() {
        const e = JSON.parse(JSON.stringify(this.getSingleCompetitionGroup));
        e.personels = [];
        if (e.personelId1) e.personels.push(e.personel1);
        if (e.personelId2) e.personels.push(e.personel2);
        if (e.personelId3) e.personels.push(e.personel3);
        if (e.personelId4) e.personels.push(e.personel4);
        return e;
      },
    },
    competition: {
      get() {
        return this.getSingleCompetition;
      },
    },
  },
  created() {
    if (this.$route.params) {
      this.$store.dispatch(
        "initSingleCompetition",
        this.$route.params.competitionId
      );
      this.$store.dispatch("initSingleCompetitionGroup", {
        groupId: this.$route.params.id,
      });
      this.$store.dispatch("initManagement");
    }
  },
  methods: {
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
    getCategory(id) {
      const found = id ? this.getManagementItems.find((e) => e.id == id) : null;
      return found ? found.title : "Yok";
    },
    openDetail(id) {
      this.$router.push({
        name: "PersonelDetail",
        params: { id: id },
      });
    },
  },
};
</script>


<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>